@keyframes arrowMovement {
    from {
        opacity: 1.0;
        transform: translateY(0em);
    }

    to {
        opacity: 0.5;
        transform: translateY(-1.5em);
    }
}

.App {
    & .AppMainLogo {
        display: grid;
        position: relative;
        width: 100%;
        height: calc(100svh + 8px);
        max-width: 100%;
        min-height: 340px;

        & > div {
            grid-area: 1 / 1;
        }

        & > .Background {
            z-index: var(--main-bg-zindex);
        }

        & > .Contents {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;

            z-index: calc(var(--main-zindex));
        }

        & > .Contents > .Flex {
            display: flex;
            flex: 1;
        }

        & > .Contents > .Move {
            --transition: transform;
        }

        & .WelcomeContainer, & .LearnMoreContainer {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .WelcomeContainer > .Welcome {
            font-weight: 200;
        }

        & .DownArrow {
            display: flex;
            align-items: center;
            height: 8svh;

            position: absolute;
            bottom: 0px;

            pointer-events: none;

            animation: arrowMovement 0.8s cubic-bezier(0.45, 0, 0.55, 1) 0s 8 alternate;
            transform: translateY(0em);

            & > a {
                pointer-events: auto;

                padding: 0px;

                & > svg {
                    width: 2em;
                    height: 2em;
                }
            }
        }

        & .LogoContainer {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            gap: 16px;

            max-width: 80vw;

            img {
                height: var(--main-logo-height);
                aspect-ratio: auto 163 / 64;
            }

            & > .Container {
                display: flex;
                flex-flow: column;
                gap: var(--main-logo-gap);
            }

            & .Animation {
                --transition: transform;
            }

            & .Icon {
                position: relative;
                transform: translateX(calc(var(--main-logo-height) * 2 * -1));

                & > img {
                    width: var(--main-logo-height);
                    aspect-ratio: auto 1 / 1;
                }
            }

            & .Logo {
                position: relative;
                transform: translateY(calc(var(--main-logo-height) * 2 * -1));
            }

            & .Mirror {
                position: relative;
                opacity: 0.1;
                transform: rotatex(180deg) translateY(calc(var(--main-logo-height) * 2 * -1));
            }
        }
    }

    & .AppMainLogo.Visible {
        & > .Contents {
            opacity: 1.0;
            visibility: visible;

            & .DownArrow {
                opacity: 1.0;
                visibility: visible;
            }
        }

        & .LogoContainer {
            opacity: 1.0;
            visibility: visible;

            & .Icon.Animation {
                transform: translateX(0px);
            }

            & .Logo.Animation {
                transform: translateY(0px);
            }

            & .Mirror.Animation {
                transform: rotatex(180deg) translateY(0px);
            }
        }
    }

    & .AppMainLogo.Fixed {
        & > .Background {
            position: fixed;
            top: 0px;
            left: 0px;
            width: calc(100% - var(--scrollbar-width));
            height: calc(100vh + 8px);
        }
    }
    & .AppMainLogo.Fixed.Contents {
        & > .Contents {
            position: fixed;
            top: 0px;
            left: 0px;
            width: calc(100% - var(--scrollbar-width));
            height: 100svh;
        }
    }
}