.App {
    & > header {
        position: fixed;
        top: 0px;
        right: 0px;
        padding-right: var(--scrollbar-width);

        z-index: var(--header-zindex);

        display: flex;
        flex-direction: row;
        height: var(--header-height);
        min-width: 80vw;

        background-color: var(--mui-palette-background-header);
        background-image: none;

        transform: translateY(calc(var(--header-height) * -1));
        --transition: transform, box-shadow;

        & > .Pre, & > .Post {
            flex: 1;
        }

        & > .Container {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: center;
            gap: 16px;

            height: 100%;

            padding: 8px 20px;

            & > .Icon, & > .Image {
                height: 40px;
                aspect-ratio: auto 263 / 64;
            }
        }
    }
}

.App.MenuOpen > header.Animation.Mid {
    opacity: 1.0;
    visibility: visible;
    transform: translateY(0);
}

@media only screen and (max-width: 600px) {
    .App {
        & > header {
            & > .Pre {
                flex: 0;
            }
        }
    }
}