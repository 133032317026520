.App {
    & > nav {
        display: flex;
        flex-flow: column;

        & > .Icon {
            display: flex;
            flex-flow: row;
            justify-content: flex-end;

            position: fixed;
            width: calc(100% - var(--scrollbar-width));
            height: var(--header-height);
            padding: 8px;

            z-index: calc(var(--header-zindex) + 1);

            & > .Button {
                --transition: color;
            }
        }

        & > .Drawer {
            flex-shrink: 0;

            & .MuiDrawer-paper {
                width: calc(var(--mainmenu-width) + var(--scrollbar-width));
                height: calc(100% - var(--header-height));
                margin-top: var(--header-height);

                background-color: var(--mui-palette-background-drawer);

                box-sizing: border-box;
                overflow-y: auto;

                opacity: 0.0;
                transition: opacity var(--drawer-animation-duration), transform var(--drawer-animation-duration), visibility var(--drawer-animation-duration) !important;

                z-index: calc(var(--header-zindex) - 1);
            }

            & .MenuItem {
            }

            & .BottomBar {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;

                padding: 8px;
                padding-right: calc(8px + var(--scrollbar-width));

                & .DarkMode {
                    display: grid;

                    & > svg {
                        position: relative;
                        grid-area: 1 / 1;
                    }
                }

                & .LanguageBar {
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    justify-content: flex-start;

                    & > button {
                        display: grid;

                        & > img, & > svg {
                            max-width: 1em;
                            max-height: 1em;

                            position: relative;
                            grid-area: 1 / 1;

                            --transition: filter;
                        }

                        & > img {
                            filter: none;
                        }

                        & > .CheckMark {
                        }
                    }

                    & > button.Selected {
                        & > img {
                            filter: grayscale(1);
                        }

                        & > .CheckMark {
                            opacity: 1.0;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.App.MenuOpen {
    & > nav {
        & > .Drawer {
            & .MuiDrawer-paper {
                opacity: 1.0;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .App {
        & > nav {
            & > .Drawer {
                & .MuiDrawer-paper {
                }

                & .LanguageBar {
                    justify-content: flex-end;
                }
            }
        }
    }
}