.App {
    & .PanBackground {
        & > div {
            width: 100%;
            min-height: 100%;

            background-repeat: repeat-x;
            background-size: var(--pan-width) var(--height);
            background-position-y: 50%;

            --property: background-position-x;
            --duration: 0ms;
            --transition: var(--property);
            --target: 50%;
            transition-duration: var(--duration);
            background-position-x: var(--target);
        }
    }
}