.App {
    --xshort-animation-duration: 125ms;
    --short-animation-duration: 250ms;
    --midshort-animation-duration: 375ms;
    --mid-animation-duration: 500ms;
    --midlong-animation-duration: 750ms;
    --long-animation-duration: 1000ms;
    --drawer-animation-duration: var(--short-animation-duration);

    --mainmenu-width: 360px;
    --main-card-sub-width: 300px;

    --header-zindex: 20;
    --overlay-zindex: 10;
    --main-bg-zindex: 1;
    --main-zindex: 5;

    --transition: z-index;

    min-width: 400px;

    & .Animation {
        transition: var(--transition);
        transition-duration: var(--mid-animation-duration);
        transition-timing-function: ease;
    }

    & .Animation[data-transition~="visibility"] {
        visibility: hidden;
        opacity: 0.0;
        transition: visibility, opacity, var(--transition);
        transition-duration: var(--mid-animation-duration);
        transition-timing-function: ease;
    }

    & .Animation[data-transition~="visibility"].Visible {
        visibility: visible;
        opacity: 1.0;
    }

    & .Animation[data-transition~="xshort"] {
        transition-duration: var(--xshort-animation-duration);
    }

    & .Animation[data-transition~="short"] {
        transition-duration: var(--short-animation-duration);
    }

    & .Animation[data-transition~="midshort"] {
        transition-duration: var(--midshort-animation-duration);
    }

    & .Animation[data-transition~="long"] {
        transition-duration: var(--long-animation-duration);
    }

    & .Animation[data-transition~="drawer"] {
        transition-duration: var(--drawer-animation-duration);
    }

    & .NoAnimation .Animation {
        transition-duration: 0s;
    }

    & > .AppOverlay {
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;

        background-color: rgba(0, 0, 0, 0.5);

        z-index: var(--overlay-zindex);
    }
}

:root.Overlay .App {
    min-height: 100vh;
    overflow-y: scroll;

    & > .AppOverlay.Animation {
        visibility: visible;
        opacity: 1;
        backdrop-filter: blur(1px);
    }
}

@media only screen and (max-width: 600px) {
    .App {
        --mainmenu-width: 100%;
    }

    .App.MenuOpen > main, .App.MenuOpen > footer {
        filter: none;
    }
}