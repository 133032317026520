.App {
    & .AppCard {
        display: grid;
        position: relative;
        scroll-margin-top: var(--header-height);

        & > div {
            grid-area: 1 / 1;
            position: relative;
        }

        & .Background {
            position: relative;
            width: calc(100% + (var(--main-card-swipe-width) * 2));
            height: 100%;

            & > .ImageCarousel.Animation {
                transform: translateX(0px);
                --transition: transform;
            }

            & > .ImageCarousel.Animation.Visible {
                transform: translateX(calc(var(--main-card-swipe-width) * -1));
            }
        }

        & > .Card {
            transform: translateX(calc(var(--main-card-swipe-width) * -1));
            --transition: transform;

            & > .Paper {
                position: relative;
                max-width: 100%;

                margin: 8px;
                padding: 80px 32px;
            }

            & > .Paper.Fill {
                margin: 0px;
                padding: 16px;
            }
        }

        & Button.InlineButton {
            color: var(--mui-palette-text-primary);
        }

        & .AvatarIcon {
            background-color: var(--mui-palette-primary-dark);
        }

        & .SocialIcon {
            color: var(--mui-palette-primary-dark);
        }
    }

    .AppCard.Visible {
        & .Card {
            opacity: 1.0;
            visibility: visible;
            transform: translateX(0px);
        }
    }
}