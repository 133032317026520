.App {
    & > footer {
        background-color: var(--mui-palette-dark-main);

        z-index: calc(var(--main-zindex) + 1);

        & .Container {
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
            justify-content: space-between;
            gap: 16px;
        }

        & .Card {
            display: flex;
            flex-flow: column;
            flex: 1 0;
            min-width: 345px;

            & > .MuiCardContent-root {
                flex-grow: 1;
            }

            h5 {
                padding-bottom: 16px;
                font-weight: 200;
            }
        }

        & .Copyright {
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            padding-top: 16px;

            text-align: end;
        }
    }
}