.AppContact {
    & .Captcha {
        display: flex;
        justify-content: flex-start;

        & > div {
            flex: 1;
        }
    }

    & .Actions {
        & .Progress {
            opacity: 0.0;
            visibility: hidden;
        }

        & .Progress.Visible {
            transition-property: visibility, opacity;
            transition-duration: var(--short-animation-duration);
        }
    }

    & .SentContainer {
        display: flex;
        flex-flow: row;

        & .Icon {
            align-self: center;
            padding-left: 24px;

            & > svg {
                font-size: 96px;
            }
        }
    }
}