.App {
    & .ImageCarousel {
        width: 100%;
        height: 100%;

        & > div.Container {
            width: 100%;
            height: 100%;

            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            transform: translateX(-0%);
            --transition: transform;

            & > div.Img {
                min-width: 100%;
                height: 100%;

                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        & > div.Container.NoAnimation {
            transition-duration: 0s;
        }
    }
}