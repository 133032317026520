.App {
    & > main {
        display: flex;
        flex-flow: column;

        font-weight: 200;
        z-index: var(--main-zindex);

        & > .Container {
            display: flex;
            flex-flow: column;

            background: transparent;

            overflow: hidden;
            z-index: calc(var(--main-zindex) + 1);
        }
    }
}